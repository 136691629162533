<!--
 * @Description: 岗位
 * @Author: 琢磨先生
 * @Date: 2022-05-18 11:35:42
 * @LastEditors: 琢磨先生
 * @LastEditTime: 2023-12-07 11:33:33
-->

<template>
  <el-card class="box">
    <el-button type="primary" size="small" @click="onEdit()" round icon="plus"
      >新增</el-button
    >
  </el-card>
  <el-card class="box data">
    <el-table :data="tableData" border>
      <!-- <el-table-column label="编号" prop="id" width="100"></el-table-column> -->
      <el-table-column label="名称" prop="name" width="200"></el-table-column>
      <el-table-column label="状态" width="100">
        <template #default="scope">
          <el-tag type="danger" v-if="scope.row.is_stop">停用</el-tag>
          <el-tag type="success" v-else>正常</el-tag>
        </template>
      </el-table-column>
      <el-table-column label="门店" min-width="300">
        <template #default="scope">
         {{scope.row.shops.map(x=>x.name).join('、')}}
        </template>
      </el-table-column>
      <el-table-column
        label="描述"
        min-width="200"
        prop="description"
      ></el-table-column>
      <el-table-column label="操作" fixed="right" width="140">
        <template #header>
          <el-button
            size="small"
            round
            icon="Tickets"
            @click="openDraggable(tableData)"
            v-if="is_can_sort"
          >排序</el-button>
        </template>
        <template #default="scope">
          <el-button type="primary" link size="small" @click="onEdit(scope.row)"
            >修改</el-button
          >
          <el-popconfirm title="去定要删除？" @confirm="onDelete(scope.row)">
            <template #reference>
              <el-button type="danger" link size="small" class="text-danger"
                >删除</el-button
              >
            </template>
          </el-popconfirm>
        </template>
      </el-table-column>
    </el-table>
  </el-card>
  <edit-post
    :item="current"
    @success="loadData"
    @close="current = null"
  ></edit-post>

  <el-dialog
    title="排序(拖拽排序)"
    v-model="visibleSortDialog"
    width="600px"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
  >
    <div>
      <draggable item-key="id" v-model="drag_list">
        <template #item="{ element, index }">
          <div class="draggable_row">{{ index + 1 }}、{{ element.name }}</div>
        </template>
      </draggable>
    </div>
    <template #footer>
      <el-button type="primary" @click="onSortSubmit" :loading="saving">确 定</el-button>
    </template>
  </el-dialog>
</template>

<script>
import EditPost from "./edit_post.vue";
import Draggable from "vuedraggable";

export default {
  components: {
    EditPost,
    Draggable
  },
  data() {
    return {
      loading: true,
      visibleSortDialog:false,
      saving:false,
      tableData: [],
      current: null,
      drag_list:[],
      is_can_edit:false,
      is_can_sort:false,
      is_can_del:false,
    };
  },
  created() {
    this.loadData();

    this.is_can_edit = this.$power("seller/v1/post/edit");
    this.is_can_del = this.$power("seller/v1/post/del");
    this.is_can_sort = this.$power("seller/v1/post/sort");
  },
  methods: {
    /**
     * 加载数据
     */
    loadData() {
      this.current = null;
      this.loading = true;
      this.$http.get("seller/v1/post").then((res) => {
        if (res.code == 0) {
          this.tableData = res.data;
        }
        this.loading = false;
      });
    },
    /**
     * 新增、修改
     */
    onEdit(item) {
      this.current = item ? item : {};
    },
    /**
     * 删除
     * @param {*} item
     */
    onDelete(item) {
      this.$http.get("seller/v1/post/del?id=" + item.id).then((res) => {
        if (res.code == 0) {
          this.$message({ type: "success", message: res.msg });
          this.loadData();
        }
      });
    },

        /**
     * 打开弹出排序框
     */
     openDraggable(list) {
      this.drag_list = list.map((item) => {
        return {
          id: item.id,
          name: item.name,
        };
      });
      this.visibleSortDialog = true;
    },

    
    /**
     * 排序
     */
    onSortSubmit(){
      this.saving = true;

      this.$http
        .post("seller/v1/post/sort", this.drag_list.map(x=>x.id))
        .then((res) => {
          if (res.code == 0) {
            this.loadData();
            this.visibleSortDialog = false;
          }
        })
        .finally(() => (this.saving = false));
    }
  },
};
</script>

<style scoped>
/* 排序 */
.draggable_row {
  height: 50px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid var(--el-border-color);
  cursor: pointer;
}

</style>