


<template>
  <el-drawer
    :title="title"
    v-model="drawer"
    direction="rtl"
    size="1000px"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    custom-class="custom"
    @closed="onClosed"
  >
    <div class="flex-column flex-grow">
      <el-form :model="form" ref="form" :rules="rules" label-width="140px">
        <el-form-item label="名称" prop="name">
          <el-col :span="10">
            <el-input v-model="form.name" placeholder="请输入名称"></el-input>
          </el-col>
        </el-form-item>
        <el-form-item label="描述" prop="description">
          <el-col :span="20">
            <el-input v-model="form.description" placeholder type="textarea"></el-input>
          </el-col>
        </el-form-item>
      </el-form>

      <!-- <el-divider content-position="left">权限策略</el-divider> -->
      <div class="flex-column flex-grow">
        <el-tabs type="border-card" class="card_tabs">
          <el-tab-pane label="权限策略">
            <el-scrollbar>
              <div style="width: 100%">
                <!-- <el-tabs>
                <el-tab-pane
                  v-for="item in app_powers"
                  :label="item.app_name"
                  :key="item.app_key"
                >
                  <el-tree
                    :ref="
                      (el) => {
                        item.ref_tree = el;
                      }
                    "
                    :data="item.powers"
                    node-key="id"
                    :expand-on-click-node="false"
                    highlight-current
                    show-checkbox
                    check-strictly
                    check-on-click-node
                    :props="{ children: 'children', label: 'name' }"
                    default-expand-all
                    @check-change="powerTreeChange"
                  /> 
                </el-tab-pane>
                </el-tabs>-->
                <el-tree
                  ref="tree"
                  :data="app_powers"
                  node-key="id"
                  :expand-on-click-node="false"
                  highlight-current
                  show-checkbox
                  check-on-click-node
                  :props="{ children: 'children', label: 'name' }"
                  default-expand-all
                  @check-change="powerTreeChange"
                />
              </div>
            </el-scrollbar>
          </el-tab-pane>
          <el-tab-pane label="门店">
            <el-checkbox-group v-model="form.shopIds">
              <el-checkbox :label="item.id" v-for="item in shop_list" :key="item.id">{{ item.name }}</el-checkbox>
            </el-checkbox-group>
          </el-tab-pane>
        </el-tabs>
      </div>
    </div>

    <template #footer>
      <el-button type="primary" @click="onSubmit" :loading="saving" :disabled="saving">确定</el-button>
    </template>
  </el-drawer>
</template>

<script>
export default {
  data() {
    return {
      loading: false,
      saving: false,
      drawer: false,
      title: "新增岗位",
      //门店
      shop_list: [],
      //策略
      app_powers: [],
      form: {
        shopIds: [],
      },
      rules: {
        name: [
          {
            required: true,
            message: "请输入名称",
            trigger: "blur",
          },
        ],
      },
      //选中的数据
      selectedPowers: [],
    };
  },
  props: ["item", "reload"],
  emits: ["close", "success"],
  watch: {
    item: {
      handler() {
        if (this.item) {
          this.drawer = true;
          if (this.item.id) {
            this.title = "修改岗位";
            this.loadDtl();
          } else {
            this.title = "新增岗位";
          }
        }
      },
      immediate: true,
    },
  },
  created() {
    this.loadShop();
    this.loadPowers();
  },
  methods: {
    /**
     *
     */
    loadShop() {
      this.$http.get("seller/v1/shop/all").then((res) => {
        if (res.code == 0) {
          this.shop_list = res.data;
        }
      });
    },

    /**
     * 加载所有的权限策略
     */
    loadPowers() {
      this.$http.get("seller/v1/post/powers").then((res) => {
        if (res.code == 0) {
          this.app_powers = res.data;
        }
      });
    },
    /**
     * 获取详情
     */
    loadDtl() {
      this.$http.get(`seller/v1/post/dtl?id=${this.item.id}`).then((res) => {
        if (res.code == 0) {
          this.saving = false;
          this.dtl = res.data;
          this.form = Object.assign({}, res.data);
          this.app_powers.forEach((x) => {
            // x.powers.forEach((item) => {
            //   this.defaultCheck(item, x.ref_tree);
            // });
            this.defaultCheck(x);
          });
        }
      });
    },

    /**
     * 修改时默认选中
     */
    defaultCheck(item) {
      if (this.dtl.powerIds.indexOf(item.id) > -1) {
        this.$refs.tree.setChecked(item, true);
      } else {
        this.$refs.tree.setChecked(item, false);
      }
      if (item.children.length > 0) {
        item.children.forEach((child) => {
          this.defaultCheck(child);
        });
      }
    },

    /**
     *
     * @param {*} nodes
     */
    powerTreeChange() {
      this.selectedPowers = [];
      // this.app_powers.forEach((app) => {
      //   var rows = this.$refs.tree.getCheckedNodes();
      //   rows.forEach((x) => {
      //     this.selectedPowers.push(x);
      //   });
      // });
      var rows = this.$refs.tree.getCheckedNodes();
      rows.forEach((x) => {
        this.selectedPowers.push(x);
      });
    },

    /**
     * 提交保存
     */
    onSubmit() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          if (this.selectedPowers) {
            this.form.powerIds = this.selectedPowers.map((x) => x.id);
          }

          this.saving = true;
          this.$http
            .post("/seller/v1/post/edit", this.form)
            .then((res) => {
              this.saving = false;
              if (res.code == 0) {
                this.drawer = false;
                this.$message({
                  type: "success",
                  message: res.msg,
                });
                this.$emit("success");
              }
            })
            .cath(() => {
              this.saving = false;
            });
        }
      });
    },
    // /**
    //  * 选择的权限策略数据
    //  */
    // tableSelectionChange() {
    //   this.selectedPowers = [];
    //   this.app_powers.forEach((app) => {
    //     var rows = app.ref_table.getSelectionRows();
    //     rows.forEach((x) => {
    //       this.selectedPowers.push(x);
    //     });
    //   });
    // },

    onClosed() {
      this.form = {};
      this.$refs.tree.setCheckedKeys(this.selectedPowers,false);
      this.selectedPowers = [];
      this.$emit("close");
    },
  },
};
</script>

<style scoped>
.custom .card_tabs {
  flex-grow: 1;
  height: 1px;
  display: flex;
  flex-direction: column;
}
</style>

<style>
.custom .el-drawer__body {
  display: flex !important;
  flex-direction: column !important;
}
.custom .el-drawer__body .el-container {
  flex-grow: 1 !important;
  height: 1px;
}

.custom .el-drawer__body .el-tabs__content {
  flex-grow: 1;
  height: 1px;
}
.custom .el-drawer__body .el-tabs__content .el-tab-pane {
  height: 100%;
}
</style>